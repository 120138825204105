<template>
  <v-container fluid grid-list-md>
    <v-layout justify-center>
      <v-flex md8>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 1.8em"> Tabelas </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap align-center>
          <v-flex v-for="(item, i) in items" :key="i">
            <v-card
              :style="'border: 1px solid LightGrey'"
              :to="item.to"
              class="mx-auto"
              max-width="400"
              max-height="80"
              hover
              v-if="mixPermissao($options.name, item.name)"
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="text-h5"
                    v-text="item.title"
                  ></v-card-title>
                  <v-card-subtitle
                    class="subheading grey--text text--darken-1"
                    v-text="item.subtitle"
                  ></v-card-subtitle>
                </div>

                <v-icon class="mr-4" x-large :color="item.icolor">
                  {{ item.icon }}
                </v-icon>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PermissoesMixin from "@/mixins/PermissoesMixin";

export default {
  name: "Tabelas",
  mixins: [PermissoesMixin],
  data: () => ({
    items: [
      {
        to: "/TabPaisesLista",
        icon: "mdi-earth",
        icolor: "light-blue darken-1",
        title: "Países *",
        subtitle: "Tabela de Países",
        name: "paises",
      },
      {
        to: "/TabEstadosLista",
        icon: "mdi-city-variant",
        icolor: "blue-grey darken-1",
        title: "Estados *",
        subtitle: "Tabela de Estados",
        name: "estados",
      },
      {
        to: "/TabMoedasLista",
        icon: "mdi-cash-multiple",
        icolor: "green darken-2",
        title: "Moedas *",
        subtitle: "Tabela de Moedas",
        name: "moedas",
      },
      {
        to: "/TabUnidadeLocalRFBLista",
        icon: "mdi-home-map-marker",
        icolor: "red darken-1",
        title: "UnidadeLocalRFB *",
        subtitle: "Tabela de UnidadeLocalRFB",
        name: "unidadelocalrfb",
      },
      {
        to: "/TabRecintoAduaneiroLista",
        icon: "mdi-map-marker-down",
        icolor: "deep-purple darken-1",
        title: "RecintoAduaneiro *",
        subtitle: "Tabela de RecintoAduaneiro",
        name: "unidadelocalrfb",
      },
      {
        to: "/TabAtualizacoesLista",
        icon: "mdi-newspaper-check",
        icolor: "orange darken-1",
        title: "Atualizações *",
        subtitle: "Cadastro de Atualizações",
        name: "atualizacoes",
      },
      {
        to: "/TabBancosLista",
        icon: "mdi-bank",
        icolor: "blue darken-2",
        title: "Bancos *",
        subtitle: "Tabela de Bancos",
        name: "bancos",
      },
    ],
  }),
};
</script>
